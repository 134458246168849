<template>
  <div>
    <h1>{{$t('locale.pageTitles.manual')}}</h1>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Manual',
}
</script>

<style lang="scss" scoped>
  // 
</style>
